.lang-select {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 16px;
}

.lang-select-visible {
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  height: 64px;
}

.lang-select-ln {
  min-width: 52px;
  text-align: center;
}

.lang-select-icon {
  width: 56px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.05);
}

.custom-search-select-list {
  position: absolute;
  top: calc(100% - 8px);
  background: #000;
  border: 1px solid #fff;
  backdrop-filter: blur(20px);
  padding: 8px;
  text-align: center;
  display: none;

}

.custom-search-select-list-item {
  height: 34px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.3);
}

.custom-search-select-list-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}


.custom-search-select-list-item.active {
  background-color: rgba(0, 0, 0, 0.1);
  color: #fff
}

.lang-select.opened path {
  fill-opacity: 1;
  stroke-opacity: 1;
}

.lang-select.opened .lang-select-visible {
  color: var(--text-color);
}

.lang-select.opened .lang-select-arrow {
  transform: rotate(-180deg);
}

.lang-select.opened .custom-search-select-list {
  display: block;
}


@media only screen and (max-width: 992px) {
  .lang-select {
    font-size: 12px;
  }

  .lang-select-visible {
    height: 32px;
    padding-right: 8px;
  }

  .lang-select-icon {
    min-width: 28px;
    width: 28px;
  }

  .lang-select-visible svg {
    width: 20px;
  }
}