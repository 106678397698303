* {
  box-sizing: border-box;

}

body,
p,
ul,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  font-family: 'Syncopate', sans-serif;
  text-transform: uppercase;
  background: #000;
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

ul {
  padding: 0;
  list-style: none;
}

img {
  max-width: 100%;
}

.btn-mobile {
  max-width: 280px;
  margin: 0 auto;
}

.btn {
  position: relative;
  display: block;
  color: #fff;
  min-width: 280px;
  padding: 13px;
  text-align: center;
  font-family: 'Syncopate', sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  background: transparent;
  border-radius: 58px;
  cursor: pointer;
}

.btn::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 58px;
  padding: 2px;
  /* control the border thickness */
  background: conic-gradient(from 180deg at 50% 50%, #FE4080 2%, #FFD740 22%, #00E5FF 27%, #A686FA 74%, #FE4080 77%);
  -webkit-mask: linear-gradient(#000 0 0) content-box,
  linear-gradient(#000 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.btn:hover {
  color: #000;
  background: conic-gradient(from 180deg at 50% 50%, #FE4080 2%, #FFD740 22%, #00E5FF 27%, #A686FA 74%, #FE4080 77%);
}

.container {
  min-width: 320px;
  max-width: 1920px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

.header .wrapper {
  position: relative;
  z-index: 11;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  border-bottom: 2px solid #fff;
}

.header__logo img {
  display: block;
  max-width: 76px;
}

.header .btn {
  display: none;
  margin-left: 26px;
}

.header__menu {
  display: flex;
  align-items: center;
}

.header__menu a {
  color: #fff;
  margin-left: 26px;

}

.header__lang {
  position: relative;
  padding-right: 13px;
}

.header__lang::after {
  content: '';
  position: absolute;
  width: 10px;
  height: 7px;
  top: 2px;
  right: 0;
  background: url(../img/lang-arrow.svg) no-repeat;
  background-size: cover;
}

.text-emph {
  padding-left: 1px;
  background: conic-gradient(from 180deg at 50% 50%, #FE4080 2%, #FFD740 22%, #00E5FF 27%, #A686FA 74%, #FE4080 77%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-stroke: 2px transparent;
  text-stroke: 2px transparent;
  color: #000;
}

.mission__list {
  margin-top: 78px;
}

.mission__item {
  padding: 10px 0;
}

.mission__item-main {
  margin-bottom: 10px;
  font-size: 20px;
}

.mission .slick-track {
  transition-delay: 10ms;
}

.mission .slick-list {
  background: #000;
  z-index: 10;
}

.mission .slick-dots {
  bottom: auto;
  top: -64px;
  justify-content: center;
}

.mission .slick-dots li {
  width: 130px;
  height: 47px;
}

.mission .slick-dots li button {
  width: 130px;
  height: 61px;
  padding: 0;
  font-size: 70px;
  font-weight: 700;
  font-family: 'Syncopate', sans-serif;
  color: #000;
  -webkit-text-stroke: 2px rgba(255, 255, 255, 0.3);
  text-stroke: 2px rgba(255, 255, 255, 0.3);
}

.mission .slick-dots li button:before {
  display: none;
}

.mission .slick-dots .slick-active button {
  color: #fff;
  -webkit-text-stroke: 2px #fff;
  text-stroke: 2px #fff;
}

.performers__list {
  margin-left: -10px;
  margin-right: -10px;
}

.performers__item {
  margin-left: 10px;
  margin-right: 10px;
  min-width: 240px;
  max-width: 290px;
  border-bottom: 2px solid transparent;
  overflow: hidden;
}

.performers__item:hover {
  border-image: linear-gradient(0.25turn, #fcd742, #fb4182);
  border-image-slice: 2;
}

.performers__item img {
  display: block;
  width: 100%;
  height: 100%;
  transition: transform 0.4s ease-out;
}

.performers__item:hover img {
  transform: scale(1.02, 1.02);
}

.performers__item-desc {
  display: block;
  position: relative;
  height: 68px;
  padding: 12px;
  margin-top: -68px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.6);
}

.performers__item-track {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.performers__item-name {
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.performers__item-links {
  display: flex;
  align-items: center;
  justify-content: center;
}

.performers__item-links a {
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.performers__item-links a:not(:first-child) {
  margin-left: 10px;
}

.performers__item-spotify {
  background-image: url(../img/icon-spotify.svg);
}

.performers__item-spotify:hover,
.performers__item-spotify:active {
  background-image: url(../img/icon-spotify-hover.png);
}

.performers__item-links .performers__item-deezer {
  width: 22px;
  height: 14px;
  border-radius: 0;
  background-image: url(../img/icon-deezer.svg);
}

.performers__item-links .performers__item-deezer:hover,
.performers__item-links .performers__item-deezer:active,
.performers__item-links .performers__item-deezer:focus {
  background-image: url(../img/icon-deezer-hover.png);
}

.performers__item-links .performers__item-yandex {
  background-image: url(../img/icon-yandex.svg);
}

.performers__item-links .performers__item-yandex:hover,
.performers__item-links .performers__item-yandex:active,
.performers__item-links .performers__item-yandex:active {
  background-image: url(../img/icon-yandex-hover.png);
}

.performers__item-links .performers__item-shazam {
  background-image: url(../img/icon-shazam.svg);
}

.performers__item-links .performers__item-shazam:hover,
.performers__item-links .performers__item-shazam:active,
.performers__item-links .performers__item-shazam:active {
  background-image: url(../img/icon-shazam-hover.png);
}

.performers {
  flex-direction: row;
  align-items: center;
  display: flex !important;
  overflow: hidden;
  position: relative;
  height: 240px;

  --animationspeed: 45s;
  --animationdelay: calc(var(--animationspeed) / 2);
}

.performers__list {
  flex-shrink: 0;
  position: absolute;
  animation-name: slidelogo;
  animation-duration: var(--animationspeed);
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  min-width: 100%;
  display: flex;
  justify-content: space-around;
}

.performers__list.delay {
  transform: translateX(100%);
  animation-name: slidelogo2;
}

.performers:hover .performers__list {
  animation-play-state: paused;
}

.performers__item {
  align-self: center;
}

@keyframes slidelogo {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes slidelogo2 {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}

.btn-desktop {
  display: none;
}

.btn-mobile {
  padding-top: 19px;
  padding-bottom: 28px;
}

.expand {
  min-height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  padding: 30px;
  background: linear-gradient(rgba(0, 0, 0, 0.7),
  rgba(0, 0, 0, 0.7)), url(../img/bg1.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  transition: background-size 0.8s ease-out;
}

.title {
  font-size: 20px;
  line-height: 22px;
  text-align: center;
}

.features {
  margin-bottom: 26px;
}

.features__item {
  margin-bottom: 20px;
  padding-bottom: 16px;
  border-bottom: 2px solid #fff;
  opacity: 0.1;
  transition: all 0.6s ease-in-out;
}

.features__item:nth-child(1) {
  opacity: 1;
}

.features__item:nth-child(2) {
  opacity: 0.3;
}

.features .features__item.highlighted {
  opacity: 1;
}

.highlighted + .features__item {
  opacity: 0.3;
}

.features__item > span {
  display: block;
}

.features__item-name {
  margin-bottom: 9px;
}

.features__item-value {
  font-size: 20px;
}

.platforms {
  margin-bottom: 20px;
}

.platforms .title {
  margin-bottom: 14px;
}

.platforms__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.platforms__item {
  width: 48%;
  aspect-ratio: 27/14;
  background-color: #191919;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.8s ease-out;
}

.platforms__item_spotify {
  background-image: url(../img/logo-spotify.svg);
  background-size: 50%;
}

.platforms__item_deezer {
  background-image: url(../img/logo-deezer.svg);
  background-size: 77%;
}

.platforms__item_amazon {
  background-image: url(../img/logo-amazon.svg);
  background-size: 50%;
}

.platforms__item_shazam {
  background-image: url(../img/logo-shazam.svg);
  background-size: 52%;
}

.platforms__item_google {
  background-image: url(../img/logo-google.svg);
  background-size: 65%;
}

.platforms__item_youtube-music {
  background-image: url(../img/logo-youtube-music.svg);
  background-size: 77%;
}

.platforms__item_youtube-red {
  background-image: url(../img/logo-youtube-red.svg);
  background-size: 68%;
}

.platforms__item_apple {
  background-image: url(../img/logo-apple.svg);
  background-size: 62%;
}

.platforms__item_yandex {
  background-image: url(../img/logo-yandex.svg);
  background-size: 85%;
}

.platforms__item:hover {
  background-color: #fff;
}

.platforms__item_spotify:hover {
  background-image: url(../img/logo-spotify-hover.svg);
  background-size: 50%;
}

.platforms__item_deezer:hover {
  background-image: url(../img/logo-deezer-hover.svg);
  background-size: 77%;
}

.platforms__item_amazon:hover {
  background-image: url(../img/logo-amazon-hover.svg);
  background-size: 50%;
}

.platforms__item_shazam:hover {
  background-image: url(../img/logo-shazam-hover.svg);
  background-size: 52%;
}

.platforms__item_google:hover {
  background-image: url(../img/logo-google-hover.svg);
  background-size: 65%;
}

.platforms__item_youtube-music:hover {
  background-image: url(../img/logo-youtube-music-hover.svg);
  background-size: 77%;
}

.platforms__item_youtube-red:hover {
  background-image: url(../img/logo-youtube-red-hover.svg);
  background-size: 68%;
}

.platforms__item_apple:hover {
  background-image: url(../img/logo-apple-hover.svg);
  background-size: 62%;
}

.platforms__item_yandex:hover {
  background-image: url(../img/logo-yandex-hover.svg);
  background-size: 85%;
}

.platforms__item_desktop {
  display: none;
}

.charge .title {
  position: relative;
  min-height: 105px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 38px;
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 20px;
  padding-right: 63%;
  text-align: left;
  overflow: hidden;
}

.charge .title::after {
  content: '';
  position: absolute;
  width: 320px;
  height: 125px;
  top: -7px;
  right: -138px;
  background: url(../img/wave.jpg) no-repeat;
  background-size: cover;
}

.charge__list {
  counter-reset: charge__item 0;;
}

.charge__item {
  position: relative;
  min-height: 73px;
  margin-bottom: 27px;
  padding-left: 77px;
  padding-right: 20px;
  padding-bottom: 10px;
  counter-increment: charge__item 1;
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(0.25turn, #fcd742, #fb4182);
  border-image-slice: 2;
}

.charge__item::before {
  content: counter(charge__item);
  position: absolute;
  width: 60px;
  height: 62px;
  top: -3px;
  left: 10px;
  font-size: 70px;
  line-height: 70px;
  font-weight: 700;
  text-align: center;
  background: conic-gradient(from 180deg at 50% 50%, #FE4080 2%, #FFD740 22%, #00E5FF 27%, #A686FA 74%, #FE4080 77%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-stroke: 4px transparent;
  text-stroke: 4px transparent;
  color: #000;
}

.charge__item-name {
  margin-bottom: 3px;
  font-size: 20px;
}

.expand2 {
  margin-bottom: 0;
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../img/bg2.jpg) no-repeat;
  background-size: auto 100%;
  background-position: center;
}

.expand2:hover {
  background-size: auto 110%;
}

.btn-mobile2 {
  padding-top: 22px;
  padding-bottom: 22px;
}

.extra {
  margin-bottom: 30px;
}

.extra__item {
  min-height: 280px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 18px;
  padding: 30px;
  text-align: center;
}

.extra__item-title {
  margin-bottom: 10px;
  font-size: 20px;
}

.extra__item-text {
  margin-bottom: 10px;
}

.extra__item_exclusive {
  background: linear-gradient(rgba(0, 0, 0, 0.6),
  rgba(0, 0, 0, 0.6)), url(../img/bg3.jpg) no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.extra__item_more {
  background: linear-gradient(rgba(0, 0, 0, 0.6),
  rgba(0, 0, 0, 0.6)), url(../img/bg4.jpg) no-repeat;
  background-size: 100% auto;
  background-position: center;
}

.extra__item:hover {
  background-size: 110% auto;
  transition: background-size 0.8s ease-out;
}

.join {
  margin-bottom: 20px;
  padding: 15px 17px 25px;
  border: 2px solid #fff;
}

.join .title {
  margin-bottom: 8px;
}

.join__info {
  text-align: center;
}

.join__form {
  counter-reset: join__form-group 0;
}

.join__form-group {
  position: relative;
  min-height: 83px;
  padding-top: 51px;
  padding-bottom: 25px;
  counter-increment: join__form-group 1;
  border-bottom: 2px solid #fff;
  margin-bottom: 5px;
  opacity: 0.1;
}

.join__form-group-active {
  padding-top: 76px;
  padding-bottom: 30px;
}

.join__form-group::before {
  content: counter(join__form-group);
  position: absolute;
  width: 70px;
  height: 69px;
  top: 7px;
  left: 50%;
  margin-left: -35px;
  font-size: 70px;
  font-weight: 700;
  text-align: center;
  -webkit-text-stroke: 2px #fff;
  text-stroke: 2px transparent;
  color: #000;
}

.join__form-group > * {
  display: none;
}

.join__form-group-active > * {
  display: block;
}

.join__form-group-active {
  opacity: 1;
}

.join__form-group-active + .join__form-group {
  opacity: 0.3;
}

.join__form-group-active + .join__form-group-active {
  opacity: 1;
}

.join__form-group-active > div,
.join__form-group-active > button {
  display: block;
}

.join__form-end {
  opacity: 0.3;
}

.join__form-group-active:nth-last-child(2) + .join__form-end {
  opacity: 1;
}

.join__form_btn {
  border: none;
  font-size: 12px;
  padding: 13px;
}

.join__form_btn:hover {
  padding: 13px;
}

.join__form_btn[disabled] {
  border: 2px solid #fff;
  opacity: 0.3;
  pointer-events: none;
  padding: 11px;
}

.join__form_btn[disabled]::before {
  background: none;
}

.join__form-group-active::before {
  background: conic-gradient(from 180deg at 50% 50%, #FE4080 -43.32deg, #FFD740 44.58deg, #00E5FF 134.94deg, #A686FA 224.06deg, #FE4080 316.68deg, #FFD740 404.58deg);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-stroke: 4px transparent;
  text-stroke: 4px transparent;
}

.join__form-music {
  display: flex;
  justify-content: space-between;
}

.join__form-input,
.join__form-select,
.join__form-textarea {
  display: block;
  width: 100%;
  margin-top: 7px;
  margin-bottom: 16px;
  padding: 11px 15px;
  font-size: 11px;
  font-family: 'Syncopate', sans-serif;
  font-weight: 700;
  color: #fff;
  border: 2px solid rgba(255, 255, 255, 0.3);
  outline: none;
  background-color: transparent;
}

.join__form-input:focus,
.join__form-select:focus,
.join__form-textarea:focus {
  border: 2px solid #fff;
}

.join__form-input.error {
  border-color: #E02351;
}

.join__form-textarea {
  min-height: 111px;
  resize: none;
}

select::-ms-expand {
  display: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none !important;
  background-image: url(../img/custom-select.svg);
  background-repeat: no-repeat;
  background-position: 96% 50%;
}

option {
  color: #000;
}

.join__form-group {
  font-size: 12px;
}

.join__form-group label {
  font-size: 12px;
  line-height: 3px;
}

.join__form-top {
  margin-bottom: 10px;
}

.join__form-attach {
  margin-bottom: 5px;
}

.join__form-file {
  margin-top: 7px;
  margin-bottom: 10px;
}

.custom-file {
  position: relative;
  overflow: hidden;
  margin-top: 8px;
  margin-bottom: 13px;
  width: auto;
  display: block;
  padding: 11px;
  font-size: 12px;
  border: 2px solid #fff;
  border-radius: 50px;
  font-family: 'Syncopate', sans-serif;
  text-align: center;
}

.custom-file-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  z-index: 100;
}

.custom-file img {
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}

ul.file-list {
  font-family: 'Syncopate', sans-serif;
  list-style: none;
  padding: 0;
}

ul.file-list li {
  font-size: 12px;
  padding-bottom: 11px;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
}

.remove-list {
  cursor: pointer;
  margin-left: 10px;
  color: #fff;
}

.join .btn {
  width: 100%;
  min-width: auto;
}

.join__form-end {
  padding-top: 20px;
}

.join__form-agree {
  flex: 1;
  display: block;
  position: relative;
  margin-bottom: 30px;
  padding-left: 35px;
  cursor: pointer;
}

.join__form-agree input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.join__form-checkbox-custom {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #fff;
}

.join__form-agree input:checked ~ .join__form-checkbox-custom {
  background-image: url(../img/icon-check.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 18px 12px;
}

.join__form-terms-label a {
  color: #fff;
  text-decoration: underline;
}

.join__form-terms-label a:hover {
  text-decoration: none;
}

.join__form-end .join__form_btn {
  flex: 1;
  padding: 16px;
}

.join__form-end .join__form_btn[disabled] {
  padding: 14px;
}

.footer {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  padding: 20px 5px;
  text-align: center;
}

.footer > div {
  margin-bottom: 20px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
}

.footer > div:not(:last-child) {
  margin-bottom: 20px;
}

.footer__privacy a {
  margin-left: 5px;
}

.footer a {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: underline;
}

.footer a:hover {
  text-decoration: none;
}

@media (min-width: 992px) {
  body {
    font-size: 18px;
  }

  .btn {
    display: block;
    max-width: 770px;
    padding: 29px;
    font-size: 18px;
  }

  .btn-mobile {
    display: none;
  }

  .btn-desktop {
    display: block;
  }

  a.btn {
    margin: 0 auto;
  }

  .container {
    padding-left: 45px;
    padding-right: 45px;
    overflow: hidden;
  }

  .header .wrapper {
    padding: 24px 0;
  }

  .header__logo img {
    max-width: 93px;
  }

  .header .btn {
    display: block;
    margin: 0 auto;
    min-width: 538px;
    padding: 18px;
  }

  .header__menu a {
    margin-left: 34px;
  }

  .header__lang {
    padding-right: 21px;
  }

  .header__lang::after {
    width: 15px;
    height: 11px;
  }

  .text-emph {
    -webkit-text-stroke: 5px transparent;
    text-stroke: 5px transparent;
    text-transform: none;
  }

  .mission {
    margin-bottom: 49px;
  }

  .mission__list {
    position: relative;
    margin-top: 41px;
  }

  .mission__list::before {
    content: '';
    position: absolute;
    left: 0px;
    top: 20px;
    width: 113px;
    height: 50px;
    background: #000;
  }

  .mission__item-main {
    margin-bottom: 36px;
    font-size: 65px;
  }

  .mission .slick-list {
    margin-left: 152px;
  }

  .mission .slick-dots {
    display: flex;
    flex-direction: column;
    top: 12px;
    left: 0;
    height: 279px;
    overflow: hidden;
  }

  .mission .slick-dots li {
    display: block;
    width: 113px;
    height: 104px;
    margin-bottom: 59px;
  }

  .mission .slick-dots li:last-child {
    margin-bottom: 0;
  }

  .mission .slick-dots li button {
    width: 113px;
    height: 104px;
    font-size: 140px;
    line-height: 115px;
  }

  .performers {
    height: 590px;
    margin-bottom: 53px;
  }

  .performers__list .slick-list {
    overflow: initial;
  }

  .performers__item {
    min-width: 590px;
    max-width: 590px;
  }

  .performers__item-desc {
    display: none;
  }

  .performers__item:hover .performers__item-desc {
    height: 98px;
    margin-top: -99px;
    padding: 5px 35px;
  }

  .performers__item-track {
    font-size: 24px;
    margin-bottom: 11px;
  }

  .performers__item-name {
    font-size: 18px;
  }

  .performers__item-links a {
    width: 30px;
    height: 30px;
    margin-left: 21px;
  }

  .performers__item-links a:first-child {
    margin-left: 0;
  }

  .performers__item-links .performers__item-spotify {
    width: 30px;
    height: 30px;
  }

  .performers__item-links .performers__item-deezer {
    width: 46px;
    height: 30px;
  }

  .performers__item:hover .performers__item-desc {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 15px;
    text-align: left;
  }

  .performers__item-text {
    max-width: 60%;
  }

  .performers__item-track {
    font-size: 14px;
    margin-bottom: 6px;
  }

  .performers__item-name {
    font-size: 12px;
  }

  .expand {
    margin-bottom: 54px;
    padding: 121px 30px 130px;
    background-size: 100%;
    background-position: left 50% top 10%;
  }

  .expand:hover {
    background-size: 110%;
  }

  .title {
    margin: 0 auto 45px;
    font-size: 60px;
    line-height: 63px;
  }

  .features {
    margin-bottom: 51px;
  }

  .features__item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 22px;
    padding-bottom: 13px;
  }

  .features__item-name {
    width: 17%;
    padding-top: 4px;
    font-size: 24px;
  }

  .features__item-value {
    width: 80%;
    font-size: 60px;
  }

  .platforms {
    margin-bottom: 53px;
  }

  .platforms__list {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(3, 33%);
    grid-gap: 25px;
    width: 100%;
  }

  .platforms__item_mobile {
    display: none;
  }

  .platforms__item_desktop {
    display: block;
  }

  .platforms__item {
    width: initial;
    aspect-ratio: 59/30;
  }

  .platforms__item_wide {
    grid-column: span 2;
    aspect-ratio: 121/30;
  }

  .platforms__item_tall {
    grid-row: span 2;
    aspect-ratio: initial;
  }

  .platforms__item_spotify {
    background-size: 55%;
  }

  .platforms__item_deezer {
    background-size: 38%;
  }

  .platforms__item_amazon {
    background-size: 50%;
  }

  .platforms__item_shazam {
    background-size: 55%;
  }

  .platforms__item_youtube {
    background-image: url(../img/logo-youtube.svg);
    background-size: 68%;
  }

  .platforms__item_google {
    background-size: 36%;
  }

  .platforms__item_apple {
    background-size: 51%;
  }

  .platforms__item_yandex {
    background-size: 44%;
  }

  .platforms__item_spotify:hover {
    background-image: url(../img/logo-spotify-hover.svg);
    background-size: 55%;
  }

  .platforms__item_deezer:hover {
    background-image: url(../img/logo-deezer-hover.svg);
    background-size: 38%;
  }

  .platforms__item_amazon:hover {
    background-image: url(../img/logo-amazon-hover.svg);
    background-size: 50%;
  }

  .platforms__item_shazam:hover {
    background-image: url(../img/logo-shazam-hover.svg);
    background-size: 55%;
  }

  .platforms__item_youtube:hover {
    background-image: url(../img/logo-youtube-hover.svg);
    background-size: 68%;
  }

  .platforms__item_google:hover {
    background-image: url(../img/logo-google-hover.svg);
    background-size: 36%;
  }

  .platforms__item_apple:hover {
    background-image: url(../img/logo-apple-hover.svg);
    background-size: 51%;
  }

  .platforms__item_yandex:hover {
    background-image: url(../img/logo-yandex-hover.svg);
    background-size: 44%;
  }

  .charge {
    margin-bottom: 30px;
  }

  .charge .title {
    display: block;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    text-align: center;
  }

  .charge .title::after {
    width: 0;
    height: 0;
  }

  .charge__list {
    position: relative;
    min-height: 518px;
    background: url(../img/wave.jpg) no-repeat;
    background-size: auto 100%;
    background-position: center;
  }

  .charge__item {
    width: 600px;
    margin-bottom: 0;
    margin-top: 20px;
    padding: 15px 15px 15px 150px;
    background: rgba(0, 0, 0, 0.7);
    transition: all 0.3s ease-out;
  }

  .charge__item:hover {
    transform: translateY(-3px);
  }

  .charge__item::before {
    width: 114px;
    height: 130px;
    font-size: 140px;
    line-height: 140px;
    top: 6px;
    left: 20px;
  }

  .charge__item-name {
    margin-bottom: 10px;
    font-size: 60px;
    hyphens: auto;
  }

  .charge__item1 {
    margin-right: auto;
  }

  .charge__item2 {
    margin-left: auto;
    margin-right: auto;
  }

  .charge__item3 {
    margin-left: auto;
  }

  .expand2 {
    margin-bottom: 32px;
  }

  .extra {
    margin-bottom: 12px;
  }

  .extra .wrapper {
    display: flex;
    justify-content: space-between;
    gap: 25px;
  }

  .extra__item {
    flex: 1;
  }

  .extra__item-title {
    width: 66%;
    margin-bottom: 42px;
    font-size: 28px;
  }

  .extra__item-text {
    font-size: 14px;
  }

  .extra__item_exclusive .extra__item-text {
    width: 66%;
  }

  .extra__item_more .extra__item-title {
    width: 50%;
  }

  .join {
    margin-bottom: 59px;
    padding: 51px 5.4%;
  }

  .join .title {
    margin-bottom: 10px;
  }

  .join__info {
    margin-bottom: 49px;
    font-size: 24px;
  }

  .join__form-group {
    position: relative;
    min-height: 213px;
    padding-top: 0;
    padding-left: 150px;
    padding-bottom: 56px;
    padding-top: 59px;
  }

  .join__form-group:first-child {
    padding-top: 49px;
  }

  .join__form-group::before {
    width: 113px;
    height: 122px;
    top: 42px;
    left: 33px;
    font-size: 140px;
    text-align: center;
  }

  .join__form-top label,
  .join__form-music > div {
    flex: 1;
    gap: 19px;
  }

  .join .join__form-group > div {
    display: none;
  }

  .join__form-group-active {
    opacity: 1;
  }

  .join__form-group-active + .join__form-group {
    opacity: 0.3;
  }

  .join__form-group-active + .join__form-group-active {
    opacity: 1;
  }

  .join__form-group-active > div {
    display: block;
  }

  .join__form-end {
    opacity: 0.3;
  }

  .join__form-group-active:nth-last-child(2) + .join__form-end {
    opacity: 1;
  }

  .join__form-agree {
    margin-bottom: 0;
  }

  .join__form_btn-wrapper {
    display: none;
  }

  .join .btn {
    width: auto;
  }

  .join .btn[disabled] {
    padding: 27px;
  }

  .join__form-group-active .btn {
    display: block;
  }

  .join .btn:hover {
    padding: 29px;
  }

  .join__form-end .join__form_btn {
    padding: 28px;
  }

  .join__form-end .join__form_btn[disabled] {
    border: 2px solid #fff;
    padding: 26px;
    opacity: 0.3;
  }

  .join__form_btn-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .join__form-group-active::before {
    background: conic-gradient(from 180deg at 50% 50%, #FE4080 -43.32deg, #FFD740 44.58deg, #00E5FF 134.94deg, #A686FA 224.06deg, #FE4080 316.68deg, #FFD740 404.58deg);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-stroke: 4px transparent;
    text-stroke: 4px transparent;
  }

  .join__form-group label {
    font-size: 11px;
    line-height: 15px;
  }

  .join .join__form-group-active .join__form-top,
  .join .join__form-group-active .join__form-music {
    display: flex;
    justify-content: space-between;
    gap: 25px;
  }

  .join__form_btn-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .join__form-input,
  .join__form-select,
  .join__form-textarea {
    margin-top: 4px;
    margin-bottom: 20px;
    font-size: 18px;
    padding: 28px;
  }

  .join__form-textarea {
    padding: 17px 28px;
  }

  .join__form-attach-wrapper,
  .join__form-original {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .join__form-original {
    align-items: flex-start;
  }

  .join__form-original select {
    margin-top: 0;
    margin-bottom: 0;
  }

  .join__form-original label {
    padding-top: 7px;
  }

  .custom-file {
    flex: 1;
    width: 76%;
    margin-top: 0;
    margin-left: 10px;
    padding: 27px;
  }

  ul.file-list {
    margin-top: 17px;
  }

  ul.file-list li {
    display: inline-block;
    margin-right: 20px;
    font-size: 11px;
  }

  .join__form-original > * {
    flex: 1;
    gap: 25px;
  }

  select {
    background-size: 15px;
  }

  .join .join__form .join__form-group-active .join__form_btn-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .join .join__form-group-active .join__form-more {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 25px;
  }

  .join__form-group-active .join__form-more label {
    flex: 1;
  }

  .join__form-group-active label {
    flex: 1;
    display: block;
  }

  .join__form-group-active.join__form-group_socials {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 25px;
  }

  .join__form-group-active.join__form-group_socials label {
    width: 49%;
    flex: initial;
  }

  .join__form-end {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 25px;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    padding: 28px 5px;
  }

  .footer > div {
    font-size: 14px;
  }

}

@media (min-width: 1500px) {
  .expand2 {
    background-size: 100%;
    background-position: left 50% top 47%;
  }

  .charge__list {
    min-height: 718px;
  }

  .charge__item {
    position: absolute;
  }

  .charge__item1 {
    top: 92px;
    left: 4%;
  }

  .charge__item2 {
    bottom: 224px;
    left: 50%;
    margin-left: -300px;
  }

  .charge__item3 {
    top: 22px;
    right: 4%;
  }

  .extra__item {
    min-height: 590px;
  }

  .extra__item-title {
    font-size: 60px;
  }

  .extra__item-text {
    margin-bottom: 32px;
    font-size: 25px;
  }

  .join__form-group label {
    font-size: 18px;
    line-height: 18px;
  }

  ul.file-list li {
    font-size: 17px;
  }

  .footer > div {
    font-size: 17px;
  }
}

.Toastify__toast-theme--light {
  background: #000;
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  border-radius: 0;
  border: 1px solid #fff;
}

.Toastify__progress-bar--error {
  background: #E02351;
}

.react-icon > div {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer__contact {
  margin: 0 16px;
}