.loadable {
  transition: all ease 0.3s;
}

.loadable.loading {
  opacity: 0.5;
  filter: grayscale(1);
  pointer-events: none;
  position: relative;
  user-select: none;
}

.loadable.loading .spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.loadable.loading * {
  cursor: default !important;
}
